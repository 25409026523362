import React, {FC, useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import EditPostModal from "./EditPostModal";
import {Link} from "react-router-dom";

interface PostItemProps {
    title: string,
    id: string,
    priority: string,
    isActive: boolean,
    category: string,
    description: string,
    seoName: string,
    post: string,
    mainImg?: string,
}

const PostItem: FC<PostItemProps> = (props) => {
    const {postStore} = useContext(Context)
    const [visible, setVisible] = useState<boolean>(false)

    const [copied, setCopied] = useState(false);
    const handleCopy = (textToCopy:string) => {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 1000); // Показує повідомлення на 2 секунди
            })
            .catch(err => {
                console.error("Помилка при копіюванні:", err);
            });
    };


    const openModal = () => {
        setVisible(!visible)
        console.log('work')
    }

    console.log(props)
    return (
        <>
            {visible &&

                <EditPostModal
                    handlePopUpOpen={openModal}
                    postId={props.id}
                    post={props.post}
                    title={props.title}
                    isActive={props.isActive}
                    category={props.category}
                    description={props.description}
                    priority={props.priority}
                />


            }
            <tr className='dashboard-list-item'>
                <td className='dashboard-list-item_main'>
                    <span className='dashboard-list-item_main_item' onClick={() => handleCopy(props.id)}>{props.id}</span>
                    <hr/>
                    <span className='dashboard-list-item_main_item' onClick={() => handleCopy(props.seoName)}>{props.seoName}</span>
                </td>

                <td className='banner_item_img_box'>
                    <img className='banner_item_img_box-img' src={props.mainImg} alt={props.mainImg}/>
                </td>
                <td className='dashboard-list-item_title'>{props.title}</td>
                <td>{props.priority}</td>
                <td>{props.isActive ? 'true' : 'false'}</td>
                <td>
                    <button onClick={openModal} className='btn btn-secondary'>edit</button>
                    {/*<Link to={'/edit' + '/' + props.id}>*/}
                    {/*    <button onClick={openModal} className='btn btn-secondary'>edit</button>*/}
                    {/*</Link>*/}
                </td>
                <td>
                    <button className='btn btn-light'>disable</button>
                </td>
            </tr>
        </>
    );
};

export default observer(PostItem);

