import React, {FC, useContext, useEffect, useState} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import SignalToken from "./SignalToken";

const OneSignalFormToken: FC = () => {
    const {postStore} = useContext(Context)

    useEffect(()=> {
         postStore.getSignalToken()
        let htmlRoot = document.getElementsByTagName("html");
        htmlRoot[0].classList.add('dark-theme');
    }, [])

    return (
        <div className='card'>
            {postStore.signalToken.map((token, i) =>
                 <SignalToken key={i} token={token}/>
            )}
        </div>
    );
};

export default observer(OneSignalFormToken);
