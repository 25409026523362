import React, {FC} from 'react';
import BackBtnLink from "../components/BackButtonLink/BackBtnLink";
import {observer} from "mobx-react-lite";

const BackBtnLinkPage:FC = (props) => {

    return (
        <div className='card'>
            <BackBtnLink/>
        </div>
    );
};

export default observer(BackBtnLinkPage);