import React, {FC, useEffect, useState} from 'react';
import ModalAgree from "../ModalAgree/ModalAgree";
import MyButton from "../UI/MyButton/MyButton";
import BackBtnLinkService from "../../services/BackBtnLinkService";

const BackBtnLinkPage:FC = (props) => {
    const [backBtnLinkValue, setBackBtnLinkValue] = useState<string>('');
    const [backBtnLinkID, setBackBtnLinkID] = useState<string>('');
    const [backBtnLinkStatusValue, setBackBtnLinkStatusValue] = useState<boolean>(true);
    const [backBtnLinkInfo, setBackBtnLinkInfo] = useState<any>([]);

    const [editForm, setEditForm] = useState<boolean>(true);
    const [openModalAgree, setOpenModalAgree] = useState<boolean>(false)

    useEffect(()=>{
        Info()
    },[editForm])

    const Info = async () => {
        const response = await BackBtnLinkService.getBackBtnLink();
        setBackBtnLinkValue(response.data[0].backBtnLinkDB);
        setBackBtnLinkStatusValue(response.data[0].backBtnLinkStatus);
        setBackBtnLinkID(response.data[0]._id);
        setBackBtnLinkInfo([{link: response.data[0].backBtnLinkDB, status: response.data[0].backBtnLinkStatus}]);
    }
    const Edit = async () => {

        const id = backBtnLinkID;
        const backBtnLink = backBtnLinkValue;
        const backBtnLinkStatus = backBtnLinkStatusValue;

        const response = await BackBtnLinkService.editBackBtnLink(id, backBtnLink, backBtnLinkStatus)
        console.log(id, backBtnLink , backBtnLinkStatus)
        Info();
    }

    const handleEditAgreement = () => {
        setOpenModalAgree(true);
    }
    const isEditToken = (status: boolean) => {
        if(status){
            Edit();
        }
        setOpenModalAgree(false);
        setEditForm(true);
    }
    return (
        <div className='card'>
            {
                openModalAgree ? <ModalAgree handleAgreement={isEditToken}/> : false
            }

            {
                backBtnLinkValue ?
                    <div className="card-body p-4">
                        <div className='col-md-12'>
                            <h4>Back Button Link</h4>
                            <p className='text-secondary'>Посилання зараз: {backBtnLinkInfo[0].link}</p>
                            <p className='text-secondary'>Статус: <span className={backBtnLinkInfo[0].status ? 'text-success' : "text-danger"}>{ backBtnLinkInfo[0].status ? 'ON' : "OFF" }</span></p>
                        </div>

                        <div className='col-md-12'>
                            <label htmlFor={'bbl1'} className="form-label text-uppercase mt-2 mb-1 text-secondary">{'Редагувати посилання'}</label>
                            <div className="input-group">
                                <span className="input-group-text "><i className={`bx bx-link`}></i></span>
                                <input  autoComplete="off"
                                        className='form-control'
                                        type='text'
                                        value={backBtnLinkValue}
                                        disabled={editForm}
                                        id={'bbl1'}
                                        placeholder='backBtnLink.com'
                                        onChange={(e) => setBackBtnLinkValue(e.target.value)}
                                        required/>
                            </div>
                        </div>
                        <div className='col-md-12 mt-3'>
                            <div className="form-check form-switch form-check-secondary">
                                <label className="form-check-label text-secondary" htmlFor="flexSwitchCheckDark">Змінити статус</label>
                                <input className="form-check-input"
                                       type="checkbox"
                                       role="switch"
                                       id="flexSwitchCheckDark"
                                       disabled={editForm}
                                       checked={backBtnLinkStatusValue}
                                       onChange={() => {
                                           setBackBtnLinkStatusValue(!backBtnLinkStatusValue)
                                       }}
                                />
                            </div>
                        </div>
                        <div className="my-3 ">
                            {
                                editForm ?
                                    <MyButton text='Редагувати' function={() => setEditForm(false)}/>
                                    :
                                    <div className='row'>
                                        <div className='mr-2 w-25'>
                                            <MyButton text='Зберегти' classItem={'bg-success'} function={handleEditAgreement}/>
                                        </div>
                                        <div className='w-25'>
                                            <MyButton text='Скасувати' classItem={'bg-danger'} function={() => setEditForm(true)}/>
                                        </div>
                                    </div>

                            }

                        </div>
                    </div>
                    :
                    <div>Loading...</div>

            }

        </div>
    );
};

export default BackBtnLinkPage;